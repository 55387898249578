import fedHolidays from '@18f/us-federal-holidays';
import { getYear, format, parseISO, addDays, eachWeekendOfYear } from 'date-fns';
import {
  PortabilityCheckResponse,
  PortingData,
  PortingRequest,
  PortingValidation,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import {
  NumberType,
  PortStatus,
} from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';

const phoneNumberRegex = /^[1-9]\d{9}$/;
const alphaNumericRegex = /^[a-zA-Z0-9]*$/;

export const USMountainTZ = 'US/Mountain';

/**
 * Helper function used to get a JS Date object from a string. If a utc formatted string
 * (e.g. 2023-10-10T00:00:00Z) is passed, it will strip the time portion of it and use
 * just the date portion of it to create the new date. If not utc, then it just returns
 * the new date object.
 * The reason we strip the time portion of the utc format is because the time zone can
 * cause the day to be off by one when creating a new Date using javascript's native Date
 * object.
 *
 * @param date string
 * @returns JS Date object
 */
const getSanitizedDate = (date: string) => {
  const re = new RegExp(
    '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$'
  );
  const isUTCFormat = re.test(date);

  if (isUTCFormat) {
    // If it's a utc formatted string, strip the time portion of it and use just the date
    // portion of it to create the new date so that the time zone doesn't cause the day to
    // be off by one.
    return parseISO(date.substring(0, 10));
  }
  return new Date(date);
};

export const splitAndTrimString = (phoneNumbers = '', deliminator = ''): string[] =>
  phoneNumbers
    .split(deliminator)
    .filter((num) => num?.trim())
    .map((num) => num?.trim());

export const validatePhoneNumbersRegex = (phoneNumbers: string): boolean => {
  for (const number of splitAndTrimString(phoneNumbers, ',')) {
    if (!phoneNumberRegex.test(number)) {
      return false;
    }
  }
  return true;
};

export const getFormattedNumbers = (numbers: string): string =>
  numbers.replace(/\+1/g, '').replace(/[-()]/g, '').replace(/\s+/g, '');

export const isHoliday = (requestedDate: string): boolean => {
  const date = getSanitizedDate(requestedDate);
  const year = getYear(date);
  return yearHolidays(year).includes(format(date, 'yyyy-MM-dd'));
};

const PORTING_FED_HOLIDAYS = [
  "New Year's Day",
  'Birthday of Martin Luther King, Jr.',
  'Memorial Day',
  'Independence Day',
  'Labor Day',
  'Thanksgiving Day',
  'Christmas Day',
];

// Returns porting holidays for the given year
// Used https://support.bandwidth.com/hc/en-us/articles/10966722302999-2025-US-Porting-Holidays as reference

export const yearHolidays = (year: number): string[] => {
  const portingFedHolidays = fedHolidays
    .allForYear(Number(year))
    .filter((holiday) => PORTING_FED_HOLIDAYS.includes(holiday.name));

  const holidays = portingFedHolidays.map((holiday) => holiday.dateString);

  const thanksGivingDay = portingFedHolidays.find(
    ({ name }) => name === 'Thanksgiving Day'
  );
  if (thanksGivingDay) {
    // Push day after thanksgiving as holiday
    holidays.push(format(addDays(thanksGivingDay.date, 1), 'yyyy-MM-dd'));
  }
  // Push christmas eve as holiday
  holidays.push(format(new Date(year, 11, 24), 'yyyy-MM-dd'));

  return holidays;
};

export const isWeekend = (requestedDate: string): boolean => {
  const da = getSanitizedDate(requestedDate);
  const isSaturday = da.getDay() === 6;
  const isSunday = da.getDay() === 0;
  return isSaturday || isSunday;
};

export const getBlackoutDates = (): string[] => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  // Get all weekends for current year
  const weekendsForCurrentYear = eachWeekendOfYear(new Date()).map((date) =>
    format(date, 'yyyy-MM-dd')
  );
  const weekendsForNextYear = eachWeekendOfYear(new Date(nextYear, 0, 1)).map((date) =>
    format(date, 'yyyy-MM-dd')
  );
  // Get all holidays for current year and next year
  const portingHolidays = [...yearHolidays(currentYear), ...yearHolidays(nextYear)];

  return [...weekendsForCurrentYear, ...weekendsForNextYear, ...portingHolidays];
};

/**
 * Helper function that takes in a date string in US format (MM/DD/YYYY) and returns
 * a date string in ISO format (YYYY/MM/DD).
 *
 * @param date A date string in US format (MM/DD/YYYY)
 * @returns string A date string in ISO format (YYYY/MM/DD)
 */
export const convertUsDateToIsoDateFormat = (date: string) => {
  if (date.length != 10) {
    console.error('incorrect date format: 1');
    return date;
  }

  const dateParts = date.split('/');
  if (dateParts.length != 3) {
    console.error('incorrect date format: 2');
    return date;
  }

  const [month, day, year] = dateParts;

  return `${year}/${month}/${day}`;
};

export const getUTCDateWithPortingTimeString = (date: string): string => {
  const parsedDate = new Date(date);

  // setting time to 15:30 UTC or 16:30 UTC because on provider side, weave provide porting time to be at 11:30 EST
  parsedDate.setHours(isDstObserved(parsedDate) ? 15 : 16);
  parsedDate.setMinutes(30);

  return format(parsedDate, `yyyy-MM-dd'T'HH:mm:ss'Z'`);
};

const stdTimezoneOffset = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (date: Date) => {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
};

export const isAlphaNumericValue = (value: string): boolean =>
  alphaNumericRegex.test(value);

export const isEqualStringUnorderedArray = (
  values1: string[],
  values2: string[]
): boolean => {
  if (values1.length !== values2.length) {
    return false;
  }
  for (const val2 of values2) {
    if (!values1.includes(val2)) {
      return false;
    }
  }
  return true;
};

export const convertedString = (originalString: string): string => {
  const convertedString = originalString.replace(/([A-Z])/g, ' $1');
  const finalString = convertedString.charAt(0).toUpperCase() + convertedString.slice(1); // Replace capital letters with spaces followed by the letter
  return finalString;
};

export const convertToDateWithSpecificTime = (dateString) => {
  // Parse the input date string
  const parts = dateString.split('/');
  const month = parseInt(parts[0], 10) - 1; // Month is zero-based
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Create a new Date object
  // Set the time to 11:30 AM ET (Eastern Time)
  // returns date in  day month date year format
  const date = new Date(year, month, day, 16, 30);
  return date;
};

export const getStatusForPorting = (portOrder: PortingData) => {
  const portingRequests = portOrder.portingRequests ?? [];
  const filteredPortRequest = portingRequests.filter(
    (request) => request?.portingStatus !== PortStatus.PORT_STATUS_DELETED
  ); /* if the status is deleted don't show it, show the status of next porting_request */

  return filteredPortRequest[0]?.portingStatus
    ? filteredPortRequest[0]?.portingStatus.replace('PORT_STATUS_', '')
    : 'Draft';
};

export const getPortOrderType = (portOrder?: PortingData): string => {
  const portOrderNumberTypes = (portOrder?.portingRequests ?? []).map(
    (item) => item?.numberType
  );

  const isSMSHosting = portOrderNumberTypes.includes(NumberType.NUMBER_TYPE_SMS);
  const isVoiceSMSPort = portOrderNumberTypes.includes(NumberType.NUMBER_TYPE_PHONE);
  const isFaxPort = portOrderNumberTypes.includes(NumberType.NUMBER_TYPE_FAX);

  if (isSMSHosting) {
    return 'SMS Hosting';
  } else if (isVoiceSMSPort && isFaxPort) {
    return 'Voice/SMS & Fax Port';
  } else if (isVoiceSMSPort) {
    return 'Voice/SMS Port';
  } else if (isFaxPort) {
    return 'Fax Port';
  }
  return '-';
};

export const getErrorMessageFromResponseError = (error: any): string => {
  try {
    const errorJson = JSON.parse(error?.response?.data?.message);
    return errorJson?.cause;
  } catch (error) {
    return '';
  }
};

type PortingRequestObjectData = Pick<
  PortingRequest,
  'phoneNumber' | 'portType' | 'numberType' | 'requestedFirmOrderCommitmentDate'
>;

export const getPortingDataObjectList = (
  port: PortingData,
  portabilityCheckResponse: PortabilityCheckResponse
): PortingData[] => {
  const portingPhoneNumberObjectMap = port?.portingRequests?.reduce<
    Map<string, PortingRequestObjectData>
  >((acc, current) => {
    const cleanPortRequestNumber = (current.phoneNumber ?? '')
      .replace(/\D/g, '')
      .replace(/^1/, '');

    acc.set(cleanPortRequestNumber, {
      phoneNumber: current.phoneNumber,
      portType: current.portType,
      numberType: current.numberType,
      requestedFirmOrderCommitmentDate: current.requestedFirmOrderCommitmentDate,
    });
    return acc;
  }, new Map<string, PortingRequestObjectData>());

  const numbersInPortObjectSet = new Set<string>();

  const {
    partnerSupportedRateCenters = [],
    unsupportedTollFreeNumbers = [],
    supportedLosingCarriers = [],
    unsupportedLosingCarriers = [],
    supportedTollFreeNumbers = [],
  } = portabilityCheckResponse;

  const phoneNumberGroupList: string[][] = [
    ...partnerSupportedRateCenters.map(({ phoneNumbers = [] }) => phoneNumbers),
    supportedTollFreeNumbers.map((tollFreeNumbers) => tollFreeNumbers),
    unsupportedTollFreeNumbers.map((tollFreeNumbers) => tollFreeNumbers),
    ...supportedLosingCarriers.map(({ phoneNumbers = [] }) => phoneNumbers),
    unsupportedLosingCarriers.flatMap(({ phoneNumbers = [] }) => phoneNumbers),
  ];

  return phoneNumberGroupList
    .map((phoneNumberGroup) => {
      const portingRequestList: PortingRequest[] = [];

      phoneNumberGroup.forEach((phoneNumber) => {
        const portingRequest = portingPhoneNumberObjectMap?.get(phoneNumber);
        if (!!portingRequest && !numbersInPortObjectSet.has(phoneNumber)) {
          numbersInPortObjectSet.add(phoneNumber);
          portingRequestList.push(portingRequest);
        }
      });

      const billingPhoneNumber =
        portingRequestList[0]?.phoneNumber ?? port.billingPhoneNumber;
      return {
        ...port,
        portingRequests: portingRequestList,
        billingPhoneNumber,
      };
    })
    .filter((portingData) => portingData.portingRequests.length > 0);
};

export const filterPortingValidations = (
  portingValidationData: PortingValidation[] = [],
  minErrorCode: number,
  maxErrorCode: number
): PortingValidation[] => {
  return portingValidationData?.filter((portingInformationData) => {
    return (
      portingInformationData.errorCode !== undefined &&
      portingInformationData.errorCode >= minErrorCode &&
      portingInformationData.errorCode <= maxErrorCode
    );
  });
};

export const getDateArray = function (start: Date, end: Date): string[] {
  const arr = new Array();
  const dt = new Date(start);
  while (dt <= end) {
    arr.push(format(new Date(dt), 'yyyy-MM-dd'));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};
