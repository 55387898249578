import { createAction } from 'redux-actions';
import { put } from 'redux-saga/effects';
import { CustomAxios } from '../../../redux/axios';
import { Tracer, tracerHeader } from '@weave/js-internal-tools';
import { REACT_APP_API_URL } from '../../../config/app';

export const enableTracer = createAction('ENABLE_TRACER');
export const disableTracer = createAction('DISABLE_TRACER');
export const enableTracerSuccess = createAction('ENABLE_TRACER_SUCCESS');
export const disableTracerSuccess = createAction('DISABLE_TRACER_SUCCESS');
export const tracerError = createAction('TRACER_ERROR');

export const isDev = (REACT_APP_API_URL as string).includes('dev');
export const isProd = !isDev;

const tracer = new Tracer(isProd);

export const handleEnableTracer = function* () {
  try {
    const tracerId = tracer.startTrace(disableTracer);
    tracer.logLinks(['wam']);
    CustomAxios.setHeader(tracerHeader, tracerId);
    yield put(enableTracerSuccess(tracerId));
  } catch {
    yield put(tracerError());
  }
};

export const handleDisableTracer = function* () {
  try {
    tracer.stopTrace();
    CustomAxios.deleteHeader(tracerHeader);
    yield put(disableTracerSuccess());
  } catch {
    yield put(tracerError());
  }
};
