import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const portingHubStyles: any = {};

portingHubStyles.portContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

portingHubStyles.portTitle = css`
  color: #333b3d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 7px;
`;

portingHubStyles.portRefresh = css`
  width: 160px;
`;

portingHubStyles.newPortOrder = css`
  width: 160px;
`;

portingHubStyles.portContainerButtons = css`
  display: flex;
  justify-content: space-between;
`;

portingHubStyles.tableHeadRow = css`
  border-radius: 10px;
  background-color: #f6f7f7;
`;

portingHubStyles.tableBody = css`
  color: #8c9496;
  font-weight: 300;
`;

portingHubStyles.dotColumn = css`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333b3d;
`;

portingHubStyles.tableActions = css`
  display: flex;
  align-items: center;
`;

portingHubStyles.checkStatusButton = css`
  width: 135px;
  font-size: 14px;
  height: 40px;
`;

portingHubStyles.viewInfoButton = css`
  margin-left: 40px;
  font-size: 14px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;

portingHubStyles.reviewButton = css`
  width: 135px;
  font-size: 14px;
  height: 40px;
`;

portingHubStyles.pingingBandwidth = css`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

portingHubStyles.statusComplete = css`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

portingHubStyles.progress = css`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

portingHubStyles.portStatusStyle = css`
  text-align: center;
`;

portingHubStyles.gotItButton = css`
  width: 250px;
  font-size: 14px;
  height: 48px;
  margin: 10px auto;
`;

portingHubStyles.tableView = css`
  th {
    border: none;
    border-bottom: none !important;
    color: #8c9496;
    font-weight: normal;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
  th:first-child {
    border-radius: 10px 0 0 10px;
  }
  tr {
    border: none;

    td {
      border: none;
      padding: 16px;
      font-size: 15px;
      vertical-align: middle;
    }
  }
  thead {
    border: none;
  }
  tbody {
    border: none;
  }
`;

portingHubStyles.loading = css`
  display: flex;
  justify-content: center;
`;
portingHubStyles.portingHubText = css`
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #8c9496;
  font-weight: 300;
`;

portingHubStyles.portingSearchBar = css`
  width: 20%;
  float: right;
  margin-top: ${weaveTheme.spacing(-3)};
  margin-right: ${weaveTheme.spacing(4)};
  margin-bottom: ${weaveTheme.spacing(2)};
`;
