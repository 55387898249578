import React, { useMemo } from 'react';
import { Column } from 'react-table-7';
import { formatCentsToCurrency } from '../../../helpers/currency';
import { PricingModel } from '../../../models/pricing.model';
import {
  dateWithoutTimeFormat,
  formatDateFromUnixTimestamp,
} from '../../../helpers/utils';
import PlanTypeChip from './plan-type-chip';
import { getPricingName } from './pricing-helpers';
import { CopyToClipboardLink } from '../../../components/shared/copy-to-clipboard/copy-to-clipboard-link';

export const usePricingColumns = () =>
  useMemo(
    (): Column<PricingModel>[] => [
      {
        Header: 'Date',
        id: 'createdAt',
        accessor: (pricing) => pricing.createdAt,
        Cell: ({ value }) => formatDateFromUnixTimestamp(value, dateWithoutTimeFormat),
      },
      {
        Header: 'Payment Plan',
        id: 'planName',
        accessor: (pricing) => getPricingName(pricing),
        Cell: ({ value }) => <PlanTypeChip planName={value} />,
      },
      {
        Header: 'CP Processing Rate',
        id: 'cpRate',
        accessor: (pricing) =>
          pricing.cardPresent?.rate ? pricing.cardPresent?.rate : 'N/A',
        Cell: ({ value }) => (!isNaN(+value) ? `${value / 100}%` : value),
      },
      {
        Header: 'CP Transaction Cost',
        id: 'cpCost',
        accessor: (pricing) =>
          pricing.cardPresent?.transactionCost
            ? pricing.cardPresent?.transactionCost
            : 'N/A',
        Cell: ({ value }) => (!isNaN(+value) ? formatCentsToCurrency(value) : value),
      },
      {
        Header: 'CNP Processing Rate',
        id: 'cnpRate',
        accessor: (pricing) => pricing.cardNotPresent?.rate,
        Cell: ({ value }) => `${value / 100}%`,
      },
      {
        Header: 'CNP Transaction Cost',
        id: 'cnpCost',
        accessor: (pricing) => pricing.cardNotPresent?.transactionCost,
        Cell: ({ value }) => formatCentsToCurrency(value),
      },
      {
        Header: 'MTTP Processing Rate',
        id: 'mttpCost',
        accessor: (pricing) => pricing.mobileTapToPay?.rate ?? 'N/A',
        Cell: ({ value }) => (!isNaN(+value) ? `${value / 100}%` : value),
      },
      {
        Header: 'MTTP Transaction Cost',
        id: 'mttpRate',
        accessor: (pricing) => pricing.mobileTapToPay?.transactionCost ?? 'N/A',
        Cell: ({ value }) => (!isNaN(+value) ? formatCentsToCurrency(value) : value),
      },
      {
        Header: 'ACH Processing Rate',
        id: 'achRate',
        accessor: (pricing) => pricing.ach?.rate ?? 'N/A',
        Cell: ({ value }) => (!isNaN(+value) ? `${value / 100}%` : value),
      },
      {
        Header: 'ACH Transaction Cost',
        id: 'achCost',
        accessor: (pricing) => pricing.ach?.transactionCost ?? 'N/A',
        Cell: ({ value }) => (!isNaN(+value) ? formatCentsToCurrency(value) : value),
      },
      {
        Header: 'User ID',
        id: 'userId',
        accessor: (pricing) => pricing.userId ?? 'N/A',
        Cell: ({ value }) => <CopyToClipboardLink label={value} copyData={value} />,
      },
    ],
    []
  );
