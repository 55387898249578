// src/helpers/authz.ts
import { handleLocalStorage } from '../redux/actions/auth/auth.helpers';
import * as authTypes from '../redux/actions/auth/auth.types';
import jwtDecode from 'jwt-decode';
import { Permission, Permission_index } from '@weave/schema-gen-ts/dist/shared/waccess';

export interface DecodedToken extends WeaveToken {
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  sub: string;
  user_id: string;
  username: string;
}

export interface WeaveToken {
  ACLS: { [key: string]: Permission_index[] };
  aud: string[];
  exp: number;
  expBuffer: number;
  iat: number;
  jti: string;
  type: 'weave' | 'internal' | 'practice';
  user_id?: string;
  username?: string;
  Locations?: string[];
  ltree: { [key: string]: string[] };
}

export function createAuthz(
  localStorageHandler = handleLocalStorage,
  jwtDecoder = jwtDecode
) {
  function hasAccess(permission: Permission): boolean {
    const ACLs = getWeaveACLs();
    if (!ACLs) {
      return false;
    }
    return ACLs.includes(Permission_index[permission]);
  }

  function getWeaveACLs(): Permission_index[] | undefined {
    return getDecodedWeaveToken()?.ACLS['weave'];
  }

  function getDecodedWeaveToken(): DecodedToken | null {
    const localToken = localStorageHandler.get(authTypes.AuthStorage.weave_token);
    if (!localToken) {
      return null;
    }
    try {
      const decoded = jwtDecoder<DecodedToken>(localToken);
      if (decoded.exp && Date.now() / 1000 >= decoded.exp) {
        localStorageHandler.delete(authTypes.AuthStorage.weave_token);
        return null;
      }
      return decoded;
    } catch (error) {
      localStorageHandler.delete(authTypes.AuthStorage.weave_token);
      return null;
    }
  }

  return {
    hasAccess,
    getWeaveACLs,
    getDecodedWeaveToken,
  };
}

// Usage
const authz = createAuthz();
export const { hasAccess, getWeaveACLs, getDecodedWeaveToken } = authz;
