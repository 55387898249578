import { isProd } from '../redux/actions/internal-tools/internal-tools.action';

export const PDS_URL = isProd ? 'https://pds.weavelab.ninja' : 'https://pds.weavedev.net';

export const getTenantURL = (
  tenantId: string,
  searchTerm: string
): { url: string; title: string } => {
  if (tenantId === '' || !tenantId) {
    return { url: `${PDS_URL}/tenants?term=${searchTerm}`, title: 'None' };
  }

  return { url: `${PDS_URL}/tenants/${tenantId}`, title: tenantId };
};
