import {
  GetLocationRegistrationStatusDetailsRequest,
  GetLocationRegistrationStatusDetailsResponse,
  GetLocationTCRBrandRequest,
  GetTCRBrandLockStatusResponse,
  GetTCRBrandLockStatusRequest,
  GetCampaignForPhoneResponse,
  AssignPhoneToCampaignRequest,
  UnassignPhoneFromCampaignRequest,
  GetCampaignForPhoneRequest,
  DetachPhoneFromCampaignRequest,
  ConnectLocationToTCRBrandRequest,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/coordinator/v1/coordinator_service.pb';
import { LocationTCRBrand } from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import {
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { CustomAxios, getResponseData } from 'redux/axios';
import { useLocationIdWithOverride } from './hooks';
import {
  assignPhoneToCampaign,
  connectLocationToTCRBrand,
  detatchPhoneFromCampaign,
  getCampaignForPhone,
  getLocationRegistrationStatusDetails,
  getLocationTCRBrand,
  unassignPhoneFromCampaign,
} from './api';

type UseLocationRegistrationStatusDetailsQueryProps = {
  locationId?: string;
};

export const useLocationRegistrationStatusDetailsQuery = (
  { locationId }: UseLocationRegistrationStatusDetailsQueryProps = {},
  options?: UseQueryOptions<GetLocationRegistrationStatusDetailsResponse>
): {
  query: UseQueryResult<GetLocationRegistrationStatusDetailsResponse>;
  key: string[];
} => {
  const resolvedLocationId = useLocationIdWithOverride(locationId);
  const disabled = !resolvedLocationId || !(options?.enabled ?? true);

  const key = [resolvedLocationId, 'get-location-registration-status-details'];
  const query = useQuery({
    queryKey: key,
    queryFn: () => {
      const payload: GetLocationRegistrationStatusDetailsRequest = {
        locationId: resolvedLocationId,
      };
      return getLocationRegistrationStatusDetails(payload);
    },
    refetchOnWindowFocus: false,
    ...options,
    enabled: !disabled,
  });

  return { query, key };
};

type UseLocationTcrBrandQueryProps = {
  locationId?: string;
};

export const useLocationTcrBrandQuery = (
  { locationId }: UseLocationTcrBrandQueryProps = {},
  options?: UseQueryOptions<LocationTCRBrand>
) => {
  const resolvedLocationId = useLocationIdWithOverride(locationId);
  const disabled = !resolvedLocationId || !(options?.enabled ?? true);

  return useQuery({
    queryKey: [resolvedLocationId, 'get-location-tcr-brand'],
    queryFn: async () => {
      const payload: GetLocationTCRBrandRequest = {
        locationId: resolvedLocationId,
      };
      return getLocationTCRBrand(payload);
    },
    refetchOnWindowFocus: false,
    ...options,
    enabled: !disabled,
  });
};

type UseLocationTCRBrandLockStatusQueryProps = {
  brandId: string;
};

export const useLocationTCRBrandLockStatusQuery = (
  { brandId }: UseLocationTCRBrandLockStatusQueryProps,
  options?: UseQueryOptions<GetTCRBrandLockStatusResponse>
) => {
  const disabled = !brandId || !(options?.enabled ?? true);

  return useQuery({
    queryKey: [brandId, 'tcr-brand-lock-status'],
    queryFn: async () => {
      const payload: GetTCRBrandLockStatusRequest = {
        brandId,
      };
      const axiosRes = await CustomAxios.get<GetTCRBrandLockStatusResponse>(
        `/tendlc/coordinator/v1/tcr-brand/lock-status`,
        { params: payload }
      );
      return getResponseData<GetTCRBrandLockStatusResponse>(axiosRes);
    },
    refetchOnWindowFocus: false,
    ...options,
    enabled: !disabled,
  });
};

export const useCampaignForPhoneQuery = (
  { phoneNumber, cnp }: GetCampaignForPhoneRequest,
  options?: UseQueryOptions<GetCampaignForPhoneResponse>
) => {
  const disabled = !phoneNumber || !(options?.enabled ?? true);

  return useQuery({
    queryKey: [phoneNumber, 'get-campaign-for-phone', cnp],
    queryFn: async () => {
      return getCampaignForPhone({ phoneNumber, cnp });
    },
    refetchOnWindowFocus: false,
    ...options,
    enabled: !disabled,
  });
};

export const useUnassignPhoneFromCampaignMutation = (
  options?: UseMutationOptions<void, Error, UnassignPhoneFromCampaignRequest>
) => {
  return useMutation(
    ['unassign-phone-from-campaign'],
    (req: UnassignPhoneFromCampaignRequest) => unassignPhoneFromCampaign(req),
    options
  );
};

export const useAssignPhoneToCampaignMutation = (
  options?: UseMutationOptions<void, Error, AssignPhoneToCampaignRequest>
) => {
  return useMutation(
    ['assign-phone-to-campaign'],
    (req: AssignPhoneToCampaignRequest) => assignPhoneToCampaign(req),
    options
  );
};

export const useDetatchPhoneFromCampaignMutation = (
  options?: UseMutationOptions<void, Error, DetachPhoneFromCampaignRequest>
) => {
  return useMutation(
    ['detach-phone-from-campaign'],
    (req: DetachPhoneFromCampaignRequest) => detatchPhoneFromCampaign(req),
    options
  );
};

export const useConnectLocationToTCRBrandMutation = (
  options?: UseMutationOptions<void, Error, ConnectLocationToTCRBrandRequest>
) => {
  return useMutation(
    ['connect-location-to-tcr-brand'],
    (req: ConnectLocationToTCRBrandRequest) => connectLocationToTCRBrand(req),
    options
  );
};
