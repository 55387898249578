import { NakedUl, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React from 'react';
import { UserLocation } from './user-location.component';
import { requestState, useGetUserLocationData } from './user-search.api';
import { removeFromLocations } from './user-access.api';
import { useAlert } from '@weave/alert-system';
import { hasAccess } from '../../helpers/authz';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess';

type UserLocationsProps = {
  userId: string;
};

export const UserLocations = ({ userId }: UserLocationsProps) => {
  const userLocations = useGetUserLocationData(userId);
  const alerts = useAlert();
  const [isLoading, setIsLoading] = React.useState(false);

  const submitRemoveFromLocations = (userId: string, locationIds: string[]) => {
    if (confirm(`Are you sure you want to remove this user from ${locationIds.length} location${locationIds.length !== 1 ? 's' : ''}? This action cannot be undone.`)) {
      setIsLoading(true);
      removeFromLocations(userId, locationIds, errorCallback)
        .then((res) => {
          return userLocations.refetch();
        })
        .then(() => {
          setIsLoading(false);
          alerts.success('Removed from all locations');
          requestState.searchTerm$.next(requestState.searchTerm$.value);
        })
        .catch((err) => {
          setIsLoading(false);
          alerts.error(err.message);
        });
    }

    function errorCallback(err: Error) {
      alerts.error(err.message);
    }
  };

  return (
    <>
      {userLocations.data?.Locations.length !== 0 ? (
        <NakedUl
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '>li': {
              marginRight: theme.spacing(1),
              marginTop: theme.spacing(1),
            },
          }}
        >
          {userLocations.isFetched &&
            userLocations.data?.Locations.map((location) => (
              <li key={location.ID}>
                <UserLocation locationId={location.ID} />
              </li>
            ))}
        </NakedUl>
      ) : (
        'No locations'
      )}
      {userLocations.isFetched && userLocations.data?.Locations.length !== 0 && (
        <>
          {hasAccess(Permission.WEAVE_USER_BULK_ADMIN) && (
            <button
              onClick={() => {
                submitRemoveFromLocations(
                  userId,
                  userLocations.data?.Locations.map((loc) => loc.ID) || []
                );
              }}
            >
              Remove from all locations
            </button>
          )}
          {isLoading && <Text>Removing from all locations...</Text>}
        </>
      )}
    </>
  );
};
