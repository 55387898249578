import { CustomAxios } from '../../redux/axios';
import { usersApi } from '../../redux/actions/users/users.api';

export const removeFromLocations = (
  userID: string,
  locationIDs: string[],
  errorCallback?: (err: Error) => void
) => {
  if (!errorCallback) {
    errorCallback = (err) => {
      console.error(err);
    };
  }
  return Promise.all(
    (locationIDs || []).map((locationID) => {
      return CustomAxios.deleteWithCustomLocationID(
        locationID,
        `/support/v1/users/${userID}`
      ).catch(errorCallback);
    })
  );
};
