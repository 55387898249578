import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { bind } from '@react-rxjs/core';

/**
 * Creates a search request state for a given search filter and search function.
 * @param defaultSearchFilter
 * @param defaultResponse
 * @param searchFunction
 */
export function createSearchRequestState<F, T>(
  defaultSearchFilter: F,
  defaultResponse: T,
  searchFunction: (searchTerm: F) => Observable<T>
) {
  const searchTerm$ = new BehaviorSubject<F>(defaultSearchFilter);
  const searchLoadingSubject$ = new BehaviorSubject<boolean>(false);
  const searchLoading$ = searchLoadingSubject$.asObservable();
  const searchResponseErrorSubject$ = new BehaviorSubject<string | null>(null);
  const searchResponseError$ = searchResponseErrorSubject$.asObservable();
  const searchResponseIsError$ = searchResponseError$.pipe(map((err) => !!err));
  const searchResponse$ = searchTerm$.pipe(
    tap(() => {
      searchLoadingSubject$.next(true);
      searchResponseErrorSubject$.next(null);
    }),
    switchMap(searchFunction),
    catchError((err) => {
      searchResponseErrorSubject$.next(err.message);
      return from([]);
    }),
    tap((resp) => {
      // side effects
      searchLoadingSubject$.next(false);
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  const [useData] = bind<T>(searchResponse$, defaultResponse);
  const [useIsLoading] = bind(searchLoading$, false);
  const [useIsError] = bind(searchResponseIsError$, false);
  const [useError] = bind(searchResponseError$, null);
  return {
    searchTerm$,
    useData,
    useIsLoading,
    useIsError,
    useError,
  };
}
