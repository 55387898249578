///////////////////////////////////// METRICS ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
import { AutoMessageType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/models.pb.js';

import { DependencyDataKeys, Result } from '../handoff-metrics.types';
import { HandoffTypeMetric } from '../handoff.constants';
import { NumberType } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';

const MISSED_CALL_SMS_PASS_VALUE = 'active';
const REVIEWS_SETTINGS_PASS_VALUE = 'active';
const FAX_NUMBER_PORT_PASS_VALUE = 'submitted';
const FAX_RECEIVED_PASS_VALUE = 1;

// MISSED CALL SMS METRIC
export const missedCallSmsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Missed call texts should be ${this.expectedValue}`;
  },
  dependencyDataKeys: [DependencyDataKeys.MessagingRules],
  expectedValue: MISSED_CALL_SMS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err) return err.message;

    const autoMessagingRules = messagingRulesQuery?.data;
    if (!autoMessagingRules) return 'no data';

    const missedCallTextsActive = autoMessagingRules.some(
      (rule) =>
        rule.autoMessageType?.slug === AutoMessageType_Slug.MISSED_CALL && rule.enabled
    );

    if (missedCallTextsActive) return MISSED_CALL_SMS_PASS_VALUE;

    return 'off';
  },
  resultFn: function (dependentData) {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err || !messagingRulesQuery?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [`Office didn't want to turn on their missed call text yet`],
};

// FAX NUMBER SUBMITTED FOR PORT METRIC
export const faxNumberSubmittedPortMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Fax should be ${this.expectedValue}`;
  },
  dependencyDataKeys: [
    DependencyDataKeys.PortingData,
    DependencyDataKeys.FaxProviderSettings,
  ],
  expectedValue: FAX_NUMBER_PORT_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const portingQueryData = dependentData[DependencyDataKeys.PortingData];

    const faxProviderData = dependentData[DependencyDataKeys.FaxProviderSettings];

    const err = portingQueryData?.error;
    if (err) return err.message;

    const portingData = portingQueryData?.data;
    if (!portingData) return 'no data';

    const hasFaxPortRequest = portingData?.portingData?.some(({ portingRequests }) =>
      portingRequests?.some(({ numberType }) => numberType === NumberType.NUMBER_TYPE_FAX)
    );

    // Adding this check in case of user error with the wrong type
    // is used for the porting type, this has happened and this check
    // goes to PDS to verify if there is any fax information as a fallback
    const faxProviderErr = faxProviderData?.error;
    if (faxProviderErr) return faxProviderErr.message;

    const hasFaxProvider = Object.hasOwn(
      faxProviderData?.data?.data.data || {},
      'provider'
    );

    if (hasFaxPortRequest || hasFaxProvider) return FAX_NUMBER_PORT_PASS_VALUE;

    return 'No fax porting data';
  },
  resultFn: function (dependentData) {
    const portingData = dependentData[DependencyDataKeys.PortingData];
    const err = portingData?.error;
    if (err || !portingData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    `Office is not using Weave's fax feature`,
    `We purchased a new fax number for them.`,
    `Office is using Weave's Fax Feature but has not yet received a fax.`,
  ],
};

// FAX RECEIVED METRIC
export const faxReceivedMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `At least ${this.expectedValue} fax received in the last week`;
  },
  dependencyDataKeys: [DependencyDataKeys.FaxHistory],
  expectedValue: FAX_RECEIVED_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const faxHistoryQuery = dependentData[DependencyDataKeys.FaxHistory];
    const err = faxHistoryQuery?.error;
    if (err) return err.message;

    const faxHistory = faxHistoryQuery?.data;
    if (!faxHistory) return 'no data';

    return faxHistory.length;
  },
  resultFn: function (dependentData) {
    const faxHistoryData = dependentData[DependencyDataKeys.FaxHistory];
    const err = faxHistoryData?.error;
    if (err || !faxHistoryData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },

  exceptions: [`Port pending`, `Small office`, `Not using Weave's fax feature`],
};

// REVIEWS SETTINGS METRIC
export const reviewsSettingsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Reviews should be set up`;
  },
  dependencyDataKeys: [DependencyDataKeys.ReviewsSettings],
  expectedValue: REVIEWS_SETTINGS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const reviewsSettingsData = dependentData[DependencyDataKeys.ReviewsSettings];
    const err = reviewsSettingsData?.error;
    if (err) return err.message;

    const reviewsSettings = reviewsSettingsData?.data;
    if (!reviewsSettings) return 'no data';
    const hasReviewsUrls = Object.values(reviewsSettings.solicitationURLs || {}).some(
      (url) => !!url
    );

    if (hasReviewsUrls) return REVIEWS_SETTINGS_PASS_VALUE;

    return 'off';
  },
  resultFn: function (dependentData) {
    const reviewsSettingsData = dependentData[DependencyDataKeys.ReviewsSettings];
    const err = reviewsSettingsData?.error;
    if (err || !reviewsSettingsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: ['Office didn’t want to turn on their reviews', 'Pending'],
};
