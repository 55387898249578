import { PrimaryButton, SearchField, Text, useForm } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Page } from 'components/shared';
import React, { useEffect, useRef } from 'react';
import { UserLookupDetails } from './user-lookup-details';
import { UserLookupErrors } from './user-lookup-errors';
import { requestState } from './user-search.api';
import { Subscribe } from '@react-rxjs/core';
import { debounce } from 'lodash';

type UserLookUpPageProps = {};

const uuidRegEx =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const isValidUUID = (text: string) => uuidRegEx.test(text);
const isValidSearch = (input: string) => {
  if (!input) return false;
  if (isValidUUID(input)) return true;
  if (input.length > 3) return true;
  return false;
};

export const UserLookUpPage = (props: UserLookUpPageProps) => {
  return (
    <Page title="User Lookup">
      <Subscribe>
        <UserLookUpComponent {...props} />
      </Subscribe>
    </Page>
  );
};

export const UserLookUpComponent = (props: UserLookUpPageProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const data = requestState.useData();
  const isLoading = requestState.useIsLoading();
  const isError = requestState.useIsError();
  const error = requestState.useError();

  const { formProps, getFieldProps, values } = useForm({
    fields: {
      search: {
        value: queryParams.get('search') || '',
        type: 'text',
        validator: (props) =>
          isValidSearch(props.value) ? '' : 'Must be a User ID or User Email',
      },
    },
    onSubmit: () => {
      requestState.searchTerm$.next(values.search || '');
    },
  });

  const debouncedSearch = useRef(
    debounce((value: string) => {
      requestState.searchTerm$.next(value);
    }, 300)
  ).current;

  useEffect(() => {
    debouncedSearch(values.search || '');
    return () => debouncedSearch.cancel();
  }, [values.search, debouncedSearch]);

  useEffect(() => {
    if (values.search && queryParams.get('search') !== values.search) {
      const newUrl =
        window.location.origin + window.location.pathname + `?search=${values.search}`;

      window.history.replaceState(null, '', newUrl);
    }
  }, [values.search]);

  return (
    <>
      <form {...formProps}>
        <Text
          weight="bold"
          as="label"
          //@ts-ignore - text props don't adapt to as
          htmlFor="user-look-up-search-field"
        >
          Enter User ID or Email:
        </Text>
        <span
          css={{
            display: 'flex',
            'div:first-of-type': {
              flex: 1,
            },
          }}
        >
          <SearchField {...getFieldProps('search')} aria-label="Search User" />
          <PrimaryButton
            id="user-look-up-search-field"
            type="submit"
            disabled={isLoading}
            style={{ width: 'auto', marginLeft: theme.spacing(2) }}
          >
            Search
          </PrimaryButton>
        </span>
      </form>
      {!isError && !!data && (
        <section
          css={(theme) => ({
            margin: theme.spacing(2),
          })}
        >
          {Array.isArray(data) ? (
            <>
              <Text>Found ({data.length}) results:</Text>
              {data.map((value) => (
                <UserLookupDetails key={value.UserID} userData={value} />
              ))}
            </>
          ) : (
            <UserLookupDetails userData={data} />
          )}
        </section>
      )}
      {isError && (
        <UserLookupErrors
          error={error}
          css={(theme) => ({
            margin: theme.spacing(2),
          })}
        />
      )}
    </>
  );
};
