import React, { useState, useEffect } from 'react';
import {
  ErrorBadgeIcon,
  FormRow,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { CustomAxios } from 'redux/axios';
import { Page } from 'components/shared';

export const BlockUsersPage = () => {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  // Use local state for each list field.
  const [userIds, setUserIds] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [ipAddresses, setIpAddresses] = useState([]);
  const [jtiTokens, setJtiTokens] = useState([]);

  const [resetSignal, setResetSignal] = useState(0);

  const isComplete =
    userIds.length > 0 ||
    userNames.length > 0 ||
    ipAddresses.length > 0 ||
    jtiTokens.length > 0;

  const handleSubmit = async () => {
    const payload = {
      userId: userIds,
      username: userNames,
      ip: ipAddresses,
      jti: jtiTokens,
    };

    try {
      const res = await CustomAxios.post('/auth-api/v3/users/reset', payload);
      clearForm();
      setResponse(JSON.stringify(res.data.status));
    } catch (err) {
      console.error('Error', err);
      setError(err);
    }
  };

  const clearForm = () => {
    setUserIds([]);
    setUserNames([]);
    setIpAddresses([]);
    setJtiTokens([]);
    setResetSignal((prev) => prev + 1);
  };

  return (
    <Page title="Block User(s)">
      <div
        css={(theme: WeaveTheme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: ${theme.spacing(0, 2)};
        `}
      >
        <p>Press enter or add a comma to add an item to a list.</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          css={(theme: WeaveTheme) => css`
            padding: ${theme.spacing(0, 2)};
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 800px;
          `}
        >
          <FormRow>
            <ListInputField
              key={`userIds-${resetSignal}`}
              onValuesChange={setUserIds}
              placeholder="Enter User IDs"
              valueType="userId"
            />
          </FormRow>
          <FormRow>
            <ListInputField
              key={`userNames-${resetSignal}`}
              onValuesChange={setUserNames}
              placeholder="Enter Usernames"
              valueType="userName"
            />
          </FormRow>
          <FormRow>
            <ListInputField
              key={`ipAddresses-${resetSignal}`}
              onValuesChange={setIpAddresses}
              placeholder="Enter IP Addresses"
              valueType="ipAddress"
            />
          </FormRow>
          <FormRow>
            <ListInputField
              key={`jtiTokens-${resetSignal}`}
              onValuesChange={setJtiTokens}
              placeholder="Enter JTI Token IDs"
              valueType="jtiToken"
            />
          </FormRow>
          <div
            css={(theme: WeaveTheme) => css`
              display: flex;
              flex-direction: row;
              gap: ${theme.spacing(2)};
              margin-top: ${theme.spacing(2)};
              width: 400px;
            `}
          >
            <PrimaryButton type="submit" disabled={!isComplete}>
              Submit
            </PrimaryButton>
            <SecondaryButton onClick={clearForm}>Cancel</SecondaryButton>
          </div>
        </form>
      </div>

      {response && (
        <section
          css={(theme: WeaveTheme) => css`
            display: flex;
            padding: ${theme.spacing(2)};
            background: ${theme.colors.gray200};
            margin-top: ${theme.spacing(2)};
            justify-content: space-between;
          `}
        >
          <Text>{response}</Text>
          <IconButton label="Edit Location" onClick={() => setResponse('')}>
            <ErrorBadgeIcon />
          </IconButton>
        </section>
      )}

      {error && (
        <section
          css={(theme: WeaveTheme) => css`
            padding: ${theme.spacing(2)};
            background: ${theme.colors.gray200};
            margin-top: ${theme.spacing(2)};
          `}
        >
          <Text>{error}</Text>
        </section>
      )}
    </Page>
  );
};

const ListInputField = ({ onValuesChange, placeholder, valueType }) => {
  const [inputValue, setInputValue] = useState('');
  const [values, setValues] = useState<string[]>([]);
  const [error, setError] = useState('');

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    setValues([]);
    setInputValue('');
    setError('');
    onValuesChange([]);
  }, [onValuesChange]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    setError('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const newEntries = inputValue
        .split(/,\s*/)
        .map((val) => val.trim())
        .filter((val) => val);

      if (!newEntries.length) return;

      if (new Set(newEntries).size !== newEntries.length) {
        setError('Duplicate values in input are not allowed');
        return;
      }

      if (valueType === 'userName') {
        const invalidEmails = newEntries.filter((val) => !validateEmail(val));
        if (invalidEmails.length > 0) {
          setError(`Invalid Email: "${invalidEmails[0]}"`);
          return;
        }
      }

      // Check for duplicates against existing values.
      const duplicateValues = newEntries.filter((val) => values.includes(val));
      if (duplicateValues.length > 0) {
        setError(`Duplicate value(s): ${duplicateValues.join(', ')}`);
        return;
      }

      const updatedValues = [...values, ...newEntries];
      setValues(updatedValues);
      onValuesChange(updatedValues);
      setInputValue('');
    }
  };

  const removeValue = (index) => {
    const updatedValues = values.filter((_, i) => i !== index);
    setValues(updatedValues);
    onValuesChange(updatedValues);
  };

  return (
    <div>
      {values.length > 0 && (
        <div
          css={(theme: WeaveTheme) => css`
            margin: ${theme.spacing(1, 0)};
          `}
        >
          Count: {values.length}
        </div>
      )}
      <div
        css={(theme: WeaveTheme) => css`
          display: flex;
          flex-wrap: wrap;
          max-height: 150px;
          overflow-y: auto;
          padding: ${theme.spacing(1)};
          width: 800px;
        `}
      >
        {values.map((val, index) => (
          <span
            key={val}
            css={(theme: WeaveTheme) => css`
              padding: ${theme.spacing(1)};
              background: ${theme.colors.gray200};
              border-radius: ${theme.borderRadius.medium};
              margin-right: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {val}
            <button type="button" onClick={() => removeValue(index)}>
              x
            </button>
          </span>
        ))}
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        css={(theme: WeaveTheme) => css`
          height: 40px;
          border: 1px solid #c3c8d2;
          border-radius: ${theme.borderRadius.small};
          margin: 0;
          padding: ${theme.spacing(0, 2)};
          width: 800px;
        `}
      />
      {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
    </div>
  );
};
