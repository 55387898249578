import { Store } from '../../store/store.model';
import { AuthReducerState, CoreACLs } from './auth.types';

export const selectIsAuthenticated = (state: Store): boolean =>
  Boolean(state?.auth?.token);

export const selectUser = (state: Store): AuthReducerState['user'] => state.auth.user;

export const selectWeaveUserAcls = (state: Store): CoreACLs[] =>
  state.auth.user.ACLS['weave'];

export const selectHasWeaveAcl = (state: Store, acl): boolean => {
  const weaveACLS = selectWeaveUserAcls(state);
  return weaveACLS && weaveACLS.indexOf(acl) >= 0;
};

export const selectAuthToken = ({ auth }: Store): String | null => auth?.token;

export const selectIsRefreshing = (state: { auth: AuthReducerState }): boolean =>
  state?.auth?.isRefreshing;
