import React, { useEffect, useState } from 'react';
import { useRequestState, useAlert } from '@weave/alert-system';
import {
  ButtonBar,
  ContentLoader,
  EmailField,
  FormRow,
  Heading,
  MultiselectField,
  PasswordField,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useForm,
  Text,
  SwitchField,
  ValidatorFieldState,
} from '@weave/design-system';
import {
  selectAvailableRoles,
  selectUserFound,
} from '../../redux/actions/users/user-roles.action';
import { UserModel } from '../../models/user.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  hasMobileAccess,
  formatAvailableOptions,
  removeDenyMobileAccessRole,
  addDenyMobileAccessRole,
  handleFormatSelectedOptions,
} from './users.helpers';
import {
  saveUserProfile,
  sendPasswordReset,
  addNewUser,
} from '../../redux/actions/users';
import { UserSearchContainer } from './user-search.container';
import isEmpty from 'lodash/isEmpty';
import { useResource } from '../shared/hooks';
import * as styles from './user-form.styles';
import { usersApi } from '../../redux/actions/users/users.api';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';
import {
  AuthService,
  DisableMFARequest,
  DisableMFAResponse,
  GetUserDetailsRequest,
  GetUserDetailsResponse,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { REACT_APP_API_URL } from '../../config/app';
import { CustomAxios } from '../../redux/axios';
import { AuthStorage, CoreACLs } from '../../redux/actions/auth/auth.types';
import { handleLocalStorage } from '../../redux/actions/auth/auth.helpers';
import { getLocationUUID } from '../../helpers/utils';
import { selectHasWeaveAcl } from '../../redux/actions/auth/auth.selectors';
import { Store } from '../../redux/store/store.model';

type Props = {
  searchedEmail: string;
  selectedUser: UserModel;
  handleEditClose: () => void;
};

function authorizedFetch<T>(
  token: string
): (url: string, reqInit: RequestInit) => Promise<T> {
  const backendApi = REACT_APP_API_URL.endsWith('/')
    ? REACT_APP_API_URL.slice(0, -1)
    : REACT_APP_API_URL;
  const locationId = getLocationUUID(window.location.pathname);
  return (url: string, reqInit: RequestInit) => {
    reqInit.headers = {
      ...reqInit.headers,
      Authorization: `Bearer ${token}`,
      ...(locationId ? { 'Location-Id': locationId } : {}),
    };
    return fetch(`${backendApi}${url}`, reqInit).then((res) => {
      if (res.status !== 200) {
        throw new Error(res.body?.toString());
      } else {
        return res.json() as Promise<T>;
      }
    });
  };
}

export const UserEditForm = ({ selectedUser, searchedEmail, handleEditClose }: Props) => {
  const availableRoles = useSelector(selectAvailableRoles);
  const userFound = useSelector(selectUserFound);
  const { loading } = useRequestState(saveUserProfile.toString());
  const [roleOptions] = useState<string[]>(availableRoles.map((role) => role.Name));
  const [creatingUser, setCreatingUser] = useState(false);
  const [mfaEnabled, setMfAEnabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const alerts = useAlert();

  useEffect(() => {
    if (selectedUser.UserID) {
      loadData(selectedUser);
    }
    return () => {
      reset();
      setCreatingUser(false);
    };
  }, [selectedUser]);

  const canAddWeaveUsers = useSelector((state: Store) =>
    selectHasWeaveAcl(state, CoreACLs.WEAVEACLMANAGE)
  );

  const setupForm = (user: UserModel) => {
    const startingValues = {
      Username: user.Username || searchedEmail || '',
      FirstName: user.FirstName || '',
      LastName: user.LastName || '',
      Roles: user.Roles ? [...user?.Roles.map((role) => role.Name)] : [],
      Password: '',
      hasMobileAccess: hasMobileAccess(user.Roles),
      TicketID: user.TicketID || '',
    };
    seedValues(startingValues);
  };

  const loadData = async (user: UserModel) => {
    try {
      // get user details
      const userDetailsRequest: GetUserDetailsRequest = {
        userId: user.UserID,
      };
      // get user details from schema-gen-ts auth-api/v3 schema
      const userDetails = await AuthService.GetUserDetails(
        authorizedFetch(handleLocalStorage.get(AuthStorage.weave_token) || '') as (
          url: string,
          reqInit: RequestInit
        ) => Promise<GetUserDetailsResponse>,
        userDetailsRequest
      );
      setMfAEnabled(userDetails.mfaEnabled || false);
    } catch {
      alerts.error("Failed to get user's MFA status");
    }

    if (userFound) {
      setupForm(user);
    } else {
      try {
        const updatedUser = await usersApi.getJobTitles(user);
        setupForm(updatedUser);
      } catch (err: any) {
        alerts.error(err?.message ? err.message : "couldn't retrieve user job titles.");
      }
    }
  };

  const handleDisableMFA = async () => {
    try {
      // disable MFA
      await AuthService.DisableMFA(
        authorizedFetch(
          handleLocalStorage.get(AuthStorage.weave_token) || ''
        ) as unknown as (
          url: string,
          reqInit: RequestInit
        ) => Promise<DisableMFAResponse>,
        {
          userId: selectedUser.UserID,
        } as DisableMFARequest
      );
      setMfAEnabled(false);
    } catch {
      alerts.error('Failed to disable MFA');
    }
  };

  const handleSubmit = (formValues) => {
    if (creatingUser && formValues.Password.length < 1) {
      alerts.error('Password is a required field.');
      return;
    }
    formValues.Roles = handleFormatSelectedOptions(formValues.Roles, availableRoles);

    /* if roles haven't changed send roles without formatting */
    if (!formValues.Roles[0].hasOwnProperty('ID')) {
      formValues.Roles = selectedUser.Roles;
    }

    if (formValues.hasMobileAccess) {
      formValues.Roles = removeDenyMobileAccessRole(formValues.Roles);
    } else {
      // no mobileAccess
      formValues.Roles = addDenyMobileAccessRole(formValues.Roles);
    }
    const user: UserModel = {
      ...formValues,
      UserID: selectedUser.UserID,
      formattedAvailableRoles: formatAvailableOptions(availableRoles),
    };

    const userPayload = {
      user: user,
      addingNewUser: userFound,
    };

    if (userFound || !isEmpty(selectedUser)) {
      dispatch(saveUserProfile(userPayload));
    } else {
      dispatch(addNewUser(user));
    }
    handleEditClose();
  };

  const validateEmail = ({ value }: ValidatorFieldState<'email'>) => {
    // prevent all getweave.com emails unless the user has the correct permissions and the email is formed like this '-service@getweave.com
    // if the email is valid return an empty string
    if (value.endsWith('getweave.com') && !canAddWeaveUsers) {
      return 'Can not use a getweave.com email.';
    }
    if (
      canAddWeaveUsers &&
      value.endsWith('getweave.com') &&
      !value.endsWith('-service@getweave.com')
    ) {
      return 'Must be a service email "-service@getweave.com"';
    }
    return '';
  };

  const { formProps, getFieldProps, seedValues, reset, isComplete } = useForm({
    fields: {
      Username: {
        type: 'email',
        required: true,
        validator: validateEmail,
      },
      FirstName: { type: 'text', required: true },
      LastName: { type: 'text', required: false },
      Roles: {
        type: 'multiselect',
        minRequired: 1,
        value: [],
      },
      Password: { type: 'password', minChars: 1, required: creatingUser },
      hasMobileAccess: { type: 'switch', value: true },
      TicketID: { type: 'text', required: true },
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const ALLOWED_DOMAINS = [
    '@demoweave.com',
    '@pattersoncompanies.com',
    '@pattersondental.com',
  ];
  const manualPasswordChangingAllowed = () => {
    return ALLOWED_DOMAINS.some((domain) => selectedUser.Username.includes(domain));
  };

  return (
    <>
      <ContentLoader show={loading} />
      {isEmpty(selectedUser) && !creatingUser && (
        <div className="form-section">
          <Text>Find a user by email address:</Text>
          <UserSearchContainer />
          <div className="create-new">
            <label>Or create a new user to add to this location.</label>
            <PrimaryButton className="create" onClick={() => setCreatingUser(true)}>
              Create New
            </PrimaryButton>
          </div>
        </div>
      )}
      {(!isEmpty(selectedUser) || creatingUser) && (
        <form {...formProps}>
          <Heading level={2}>Personal Info</Heading>
          {!creatingUser && (
            <dl>
              <dt>User ID</dt>
              <dd>
                <CopyToClipboardButton>{selectedUser.UserID}</CopyToClipboardButton>
              </dd>
            </dl>
          )}
          <FormRow>
            <EmailField
              disabled={userFound}
              {...getFieldProps('Username')}
              label="Email"
            />
          </FormRow>
          <FormRow>
            <TextField
              disabled={userFound}
              {...getFieldProps('FirstName')}
              label="First Name"
            />
          </FormRow>
          <FormRow>
            <TextField
              disabled={userFound}
              {...getFieldProps('LastName')}
              label="Last Name"
            />
          </FormRow>
          {!userFound && (
            <>
              <Heading level={2}>Password</Heading>
              {(creatingUser || manualPasswordChangingAllowed()) && (
                <FormRow>
                  <PasswordField
                    {...getFieldProps('Password')}
                    label={creatingUser ? 'Password' : 'Reset Manually'}
                  />
                </FormRow>
              )}
              {!creatingUser && (
                <>
                  <Text>Reset via Email:</Text>
                  <FormRow>
                    <PrimaryButton
                      onClick={() => dispatch(sendPasswordReset(selectedUser))}
                      type="button"
                    >
                      Send Reset Email
                    </PrimaryButton>
                  </FormRow>
                </>
              )}
              <br />
            </>
          )}
          <Heading level={2}>MFA Status</Heading>
          {mfaEnabled ? (
            <div>
              <span>MFA is enabled</span>{' '}
              <SecondaryButton type="button" onClick={handleDisableMFA}>
                Disable MFA
              </SecondaryButton>
            </div>
          ) : (
            <div>MFA is disabled</div>
          )}
          <Heading level={2}>Roles & Permissions</Heading>
          <br />
          <FormRow>
            <MultiselectField {...getFieldProps('Roles')} label="Roles">
              {roleOptions?.map((role, i) => (
                <MultiselectField.Option key={i} value={role}>
                  {role}
                </MultiselectField.Option>
              ))}
            </MultiselectField>
          </FormRow>
          <FormRow>
            <SwitchField
              {...getFieldProps('hasMobileAccess')}
              name="hasMobileAccess"
              label="Access the Weave Mobile App"
              css={styles.RadioMobileAccess}
            />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('TicketID')} label="Ticket ID" />
          </FormRow>
          <Text>Please enter the ticket number associated with this change.</Text>
          <ButtonBar>
            <SecondaryButton type="button" onClick={handleEditClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={!isComplete} type="submit">
              Submit
            </PrimaryButton>
          </ButtonBar>
        </form>
      )}
    </>
  );
};
