import React from 'react';
import { Text } from '@weave/design-system';

export const BrandInfoText = () => {
  return (
    <>
      <Text>
        DO NOT USE UNLESS ABSOLUTELY NECESSARY! PLEASE READ AND COMPREHEND THE FOLLOWING
        INFORMATION.
      </Text>
      <Text>
        This button associates this location with the 10DLC Brand for Weave
        Communications, Inc. itself. The location's default sms phone number will be
        registered to a dedicated demo/testing campaign associated with Weave. There are
        limits to how many phone numbers we can have associated with Weave campaigns, so
        using this button unnecessarily fills up slots that other people need.
      </Text>
      <Text>
        DO NOT USE THIS BUTTON FOR REAL CUSTOMER LOCATIONS. ESPECIALLY DO NOT USE THIS
        BUTTON FOR NEW FEATURES INVOLVING SENDING SMS FROM WEAVE TO OUR CUSTOMERS.
      </Text>
    </>
  );
};
