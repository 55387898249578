import {
  Accordion,
  ConfirmationModal,
  DropdownField,
  IconButton,
  InfoIcon,
  Modal,
  phone,
  PrimaryButton,
  SpinningLoader,
  Table,
  TableColumnConfig,
  Text,
  TextButton,
  useFormField,
  useModalControl,
} from '@weave/design-system';
import { Page } from 'components/shared';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';
import { randomStringGenerator } from 'helpers/random-string-generator';
import { useSelector } from 'react-redux';
import { selectCurrentLocation, selectCurrentLocationId } from 'redux/actions/location';
import React, { useEffect, useMemo, useState } from 'react';
import { InterpolationWithTheme, css } from '@emotion/core';
import { theme } from '@weave/theme';
import {
  RegisterPhoneNumberArgs,
  registerPhoneNumber,
  useAssignPhoneToCampaignMutation,
  useCampaignForPhoneQuery,
  useConnectLocationToTCRBrandMutation,
  useDetatchPhoneFromCampaignMutation,
  useLocationRegistrationStatusDetailsQuery,
  useLocationTCRBrandLockStatusQuery,
  useLocationTcrBrandQuery,
  useUnassignPhoneFromCampaignMutation,
} from 'apis/tendlc';
import { RegistrationStatusIndicator } from './components/registration-status-indicators.component';
import {
  PhoneRegistrationStatus,
  PhoneRegistrationStatusDetails,
  TCRCNP,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { RegisterButton } from './components/register-button.component';
import { startCase } from 'lodash';
import { setIsLoading } from 'components/outage-alerts/outage-alerts-details/outage-alerts-details-reducer';
import { useAlert } from '@weave/alert-system';
import { useQueryClient } from 'react-query';
import { GetLocationRegistrationStatusDetailsResponse } from '@weave/schema-gen-ts/dist/schemas/tendlc/coordinator/v1/coordinator_service.pb';
import { selectWeaveUserAcls } from 'redux/actions/auth/auth.selectors';
import { AssignmentStatus_Enum } from '@weave/schema-gen-ts/dist/schemas/fsms/telnyx/v1/telnyx_service.pb';
import { BrandInfoText } from './components/brand-info-text';

const TCR_REF_VERSION = 1;
const WEAVE_BRAND_ID = 'B6LGYLE';

export const TenDLCPage = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const locationData = useSelector(selectCurrentLocation);
  const userAcls = useSelector(selectWeaveUserAcls);
  const queryClient = useQueryClient();
  const alert = useAlert();
  const [referenceId, setReferenceId] = useState<string>();
  const [showOnlyDefault, setShowOnlyDefault] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] =
    useState<RegisterPhoneNumberArgs>();
  const locationTcrBrandQuery = useLocationTcrBrandQuery();
  const locationTcrBrandLockedQuery = useLocationTCRBrandLockStatusQuery({
    brandId: locationTcrBrandQuery.data?.tcrBrand.brandId ?? '',
  });
  const {
    query: locationRegistrationStatusDetailsQuery,
    key: locationRegistrationStatusDetailsKey,
  } = useLocationRegistrationStatusDetailsQuery();

  const defaultPhoneData = locationRegistrationStatusDetailsQuery.data
    ?.defaultSmsStatusDetails as PhoneRegistrationStatusDetails;
  const defaultPhoneNumber = defaultPhoneData?.phoneNumber;
  const defaultSmsIsRegistered = defaultPhoneData?.registered;

  const phoneNumberUnsafeDropdownField = useFormField(
    {
      type: 'dropdown',
      value: defaultPhoneNumber ?? '',
    },
    [defaultPhoneNumber]
  );

  const phoneNumberRareDropdownField = useFormField(
    {
      type: 'dropdown',
      value: defaultPhoneNumber ?? '',
    },
    [defaultPhoneNumber]
  );

  const findDetailsByPhoneNumber = (phoneNumber: string | undefined) => {
    return locationRegistrationStatusDetailsQuery?.data?.allStatusDetails?.find(
      (detail) => detail.phoneNumber === phoneNumber
    );
  };

  const getCnpByPhoneNumber = (phoneNumber: string | undefined) => {
    return findDetailsByPhoneNumber(phoneNumber)?.cnp || defaultPhoneData?.cnp;
  };

  const getCampaignIdByPhoneNumber = (phoneNumber: string | undefined) => {
    return (
      findDetailsByPhoneNumber(phoneNumber)?.campaignId || defaultPhoneData?.campaignId
    );
  };

  const {
    data: campaignDetails,
    refetch: fetchCampaignForPhoneData,
    isLoading: isLoadingCampaignDetails,
  } = useCampaignForPhoneQuery(
    {
      phoneNumber: phoneNumberUnsafeDropdownField?.value,
      cnp: getCnpByPhoneNumber(phoneNumberUnsafeDropdownField?.value),
    },
    {
      enabled: false,
      onSuccess: () => {
        openCampaignDetailsModal();
      },
    }
  );

  const { mutate: assignPhone, isLoading: isAssigningPhone } =
    useAssignPhoneToCampaignMutation({
      onSuccess: () => {
        alert.success({
          message: 'Successfully assigned phone number.',
        });
      },
      onError: () => {
        alert.error({
          message: 'Failed to assign phone number.',
        });
      },
    });

  const { mutate: unassignPhone, isLoading: isUnassigningPhone } =
    useUnassignPhoneFromCampaignMutation({
      onSuccess: () => {
        alert.success({
          message: 'Successfully unassigned phone number.',
        });
      },
      onError: () => {
        alert.error({
          message: 'Failed to unassign phone number.',
        });
      },
    });

  const { mutate: detachPhone, isLoading: isDetachingPhone } =
    useDetatchPhoneFromCampaignMutation({
      onSuccess: () => {
        alert.success({
          message: 'Successfully detached phone number.',
        });
      },
      onError: () => {
        alert.error({
          message: 'Failed to detach phone number.',
        });
      },
    });

  const { mutate: connectLocation, isLoading: isConnectingLocation } =
    useConnectLocationToTCRBrandMutation({
      onSuccess: () => {
        alert.success({
          message: 'Successfully connected location to Weave brand.',
        });
      },
      onError: () => {
        alert.error({
          message: 'Failed to connect location to Weave brand.',
        });
      },
      onSettled: () => {
        connectToWeaveBrandConfirmationModalProps.onClose();
      },
    });

  const handleDetachPhone = (phoneNumber: string) => {
    const phoneStatus = allStatusDetails[phoneNumber];

    if (
      phoneStatus?.status ===
      PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_IN_PROGRESS
    ) {
      alert.error('Cannot detach phone while registration is in progress');
      return;
    }

    detachPhone({
      phoneNumber,
      locationId,
    });
  };

  const colConfig = useMemo(
    (): TableColumnConfig<PhoneRegistrationStatusDetails>[] => [
      {
        id: 'default',
        Header: <Header title="Default" />,
        headerLabel: 'Default',
        accessor: (row) => (row.phoneNumber === defaultPhoneNumber ? 'Default' : 'Other'),
        cellRenderer: (value) => <Text size="medium">{value}</Text>,
        width: 100,
        cellAlign: 'center',
      },
      {
        id: 'phone-number',
        Header: <Header title="Phone Number" />,
        headerLabel: 'Phone Number',
        accessor: (row) => row.phoneNumber,
        cellRenderer: (value) => <Text size="medium">{value}</Text>,
        width: 120,
        cellAlign: 'center',
      },
      {
        id: 'registered',
        Header: <Header title="Registered" />,
        headerLabel: 'Registered',
        accessor: (row) => row.registered,
        cellRenderer: (value) => (
          <RegistrationStatusIndicator.Phone registered={value} hideTitle unstyled />
        ),
        width: 130,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'registration-status',
        Header: <Header title="Registration Status" />,
        headerLabel: 'Registration Status',
        accessor: (row): string =>
          row.status.replace('PHONE_REGISTRATION_STATUS_', '').replace(/_/g, ' '),
        cellRenderer: (value: string) => (
          <Text size="small" textAlign="center">
            {startCase(value.toLowerCase())}
          </Text>
        ),
        width: 130,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'registration-details',
        Header: <Header title="Registration Details" />,
        headerLabel: 'Registration Details',
        accessor: (row) => row.details,
        cellRenderer: (value) => (
          <Text size="small" textAlign="left">
            {value || 'N/A'}
          </Text>
        ),
        width: 250,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'registration-status-timestamp',
        Header: <Header title="Registration Timestamp" />,
        headerLabel: 'Registration Timestamp',
        accessor: (row) =>
          row.statusDate ? new Date(row.statusDate).toLocaleString() : 'None',
        cellRenderer: (value) => (
          <Text size="small" textAlign="center">
            {value}
          </Text>
        ),
        width: 130,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'provider',
        Header: <Header title="Provider" />,
        headerLabel: 'Provider',
        accessor: (row) => row.cnp.replace('TCR_CNP_', '').replace(/_/g, ' '),
        cellRenderer: (value) => (
          <Text size="small" textAlign="center">
            {value}
          </Text>
        ),
        width: 120,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'campaign-id',
        Header: <Header title="Campaign ID" />,
        headerLabel: 'Campaign ID',
        accessor: (row) => row.campaignId || 'None',
        cellRenderer: (value) =>
          value === 'None' ? (
            <Text size="small">{value}</Text>
          ) : (
            <CopyToClipboardButton copyText={value}>
              <Text
                css={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                size="small"
                textAlign="center"
              >
                {value}
              </Text>
            </CopyToClipboardButton>
          ),
        width: 150,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'begin-registration',
        Header: <Header title="Actions" />,
        sticky: 'right',
        headerLabel: 'Actions',
        resizable: false,
        accessor: (row) => row,
        cellRenderer: (row: PhoneRegistrationStatusDetails) => (
          <RegisterButton
            phoneRegistration={row}
            onClick={async () => {
              setSelectedPhoneNumber({
                phoneNumber: row.phoneNumber,
                locationId: row.locationId,
                cnp: row.cnp,
              });

              if (row.phoneNumber === defaultPhoneNumber) {
                await handleRegistration({
                  phoneNumber: row.phoneNumber,
                  locationId: row.locationId,
                  cnp: row.cnp,
                });
              } else {
                setShowConfirmationModal(true);
              }
            }}
          />
        ),
        cellAlign: 'center',
        maxWidth: 130,
        minWidth: 130,
        filterable: false,
        sortable: false,
      },
    ],
    [defaultPhoneNumber, locationRegistrationStatusDetailsQuery.data]
  );

  const { modalProps: campaignDetailsModalProps, openModal: openCampaignDetailsModal } =
    useModalControl();

  const { modalProps: detachPhoneInfoModalProps, openModal: openDetachPhoneInfoModal } =
    useModalControl();

  const {
    modalProps: connectToWeaveBrandModalProps,
    openModal: openConnectToWeaveBrandModal,
  } = useModalControl();
  const {
    modalProps: connectToWeaveBrandConfirmationModalProps,
    openModal: openConnectToWeaveBrandConfirmationModal,
  } = useModalControl();

  // ACLs T2 and above
  const requiredAcls = [401, 406, 410, 600];
  const hasRequiredAcls = requiredAcls.every((acl) => userAcls?.includes(acl));

  const assignmentStatusMap = {
    [AssignmentStatus_Enum.UNSPECIFIED]: 'Unspecified',
    [AssignmentStatus_Enum.ASSIGNED]: 'Assigned',
    [AssignmentStatus_Enum.PENDING_ASSIGNMENT]: 'Pending Assignment',
    [AssignmentStatus_Enum.FAILED_ASSIGNMENT]: 'Failed Assignment',
    [AssignmentStatus_Enum.PENDING_UNASSIGNMENT]: 'Pending Unassignment',
    [AssignmentStatus_Enum.FAILED_UNASSIGNMENT]: 'Failed Unassignment',
  };

  useEffect(() => {
    if (locationRegistrationStatusDetailsQuery.data && !defaultPhoneData) {
      setShowOnlyDefault(false);
      alert.warning({
        message: 'This location has no default SMS number',
      });
    }
  }, [locationRegistrationStatusDetailsQuery.data]);

  useEffect(() => {
    if (locationId) {
      setReferenceId(
        `${TCR_REF_VERSION}:${locationId}:${randomStringGenerator({
          length: 4,
        })}`
      );
    }
  }, [locationId]);

  const isLoading =
    locationRegistrationStatusDetailsQuery.isLoading ||
    !locationRegistrationStatusDetailsQuery.isFetched ||
    locationTcrBrandQuery.isLoading ||
    locationTcrBrandLockedQuery.isLoading;

  if (isLoading) {
    return <Page title="10DLC" loading />;
  } else if (
    !locationRegistrationStatusDetailsQuery.data ||
    locationRegistrationStatusDetailsQuery.isError
  ) {
    return (
      <Page title="10DLC">
        <Text>
          There was an error fetching this location's phone registration details.
        </Text>
      </Page>
    );
  }

  const showRegistrationResultAlert = (
    responseDetails: PhoneRegistrationStatusDetails
  ) => {
    const { status, phoneNumber, details } = responseDetails;
    if (status === PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_REGISTERED) {
      alert.success({
        message: `Phone number ${phoneNumber} registered successfully!`,
      });
    } else if (
      status === PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_PENDING ||
      status === PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_IN_PROGRESS
    ) {
      alert.warning({
        message: `Phone number ${phoneNumber} registration is pending. Please check back later.`,
      });
    } else {
      const friendlyStatus = startCase(
        status.replace('PHONE_REGISTRATION_STATUS_', '').replace(/_/g, ' ').toLowerCase()
      );
      alert.error({
        message: `Phone number ${phoneNumber} registration failed with status: ${friendlyStatus}.${
          details ? ` The following details were provided: ${details}` : ''
        }`,
      });
    }
  };

  const handleRegistration = async (request: RegisterPhoneNumberArgs) => {
    try {
      const newRow = await registerPhoneNumber(request);
      queryClient.setQueryData<GetLocationRegistrationStatusDetailsResponse>(
        locationRegistrationStatusDetailsKey,
        (oldData) => {
          if (!oldData) {
            return {
              defaultSmsStatusDetails: {
                ...newRow.statusDetails,
              },
              allStatusDetails: [newRow.statusDetails],
            };
          }

          const isDefaultNumber = newRow.statusDetails.phoneNumber === defaultPhoneNumber;
          return {
            defaultSmsStatusDetails: isDefaultNumber
              ? newRow.statusDetails
              : oldData.defaultSmsStatusDetails,
            allStatusDetails: oldData.allStatusDetails.map((row) =>
              row.phoneNumber === newRow.statusDetails.phoneNumber
                ? newRow.statusDetails
                : row
            ),
          };
        }
      );
      showRegistrationResultAlert(newRow.statusDetails);
      await locationTcrBrandQuery.refetch();
    } catch {
      alert.error({
        message:
          'Something went wrong while registering the phone number. Please try again later.',
      });
    }
  };

  const handleCloseModal = () => {
    setSelectedPhoneNumber(undefined);
    setShowConfirmationModal(false);
    setIsLoading(false);
  };

  const { allStatusDetails } = locationRegistrationStatusDetailsQuery.data;
  const rows =
    showOnlyDefault && !!defaultPhoneData
      ? [defaultPhoneData]
      : allStatusDetails.sort((a) => (a.phoneNumber === defaultPhoneNumber ? -1 : 0));

  const showLoader =
    isAssigningPhone ||
    isUnassigningPhone ||
    isDetachingPhone ||
    isConnectingLocation ||
    isLoadingCampaignDetails;

  const specialActionsDisabled =
    !defaultPhoneData ||
    isAssigningPhone ||
    isUnassigningPhone ||
    isDetachingPhone ||
    isConnectingLocation;

  return (
    <Page
      title="10DLC"
      subtitle={
        <div css={registrationStatusIndicatorsStyles.wrapper}>
          {locationTcrBrandQuery.data && (
            <RegistrationStatusIndicator.Brand
              locationTcrBrand={locationTcrBrandQuery.data}
              locked={!!locationTcrBrandLockedQuery.data?.locked}
            />
          )}
          <RegistrationStatusIndicator.Phone registered={!!defaultSmsIsRegistered} />
        </div>
      }
      headerActions={
        !!referenceId && (
          <div css={referenceIdStyles.wrapper}>
            <CopyToClipboardButton copyText={referenceId}>
              <></>
            </CopyToClipboardButton>
            <div css={referenceIdStyles.text}>
              <Text weight="bold">TCR Reference ID</Text>
              <Text size="medium">{referenceId}</Text>
            </div>
          </div>
        )
      }
    >
      {allStatusDetails.length > 0 && (
        <>
          <Table
            tableInstanceId="tendlc-phone-registration-data-table"
            data={rows}
            colConfig={colConfig}
            // There's a weird type issue that will throw a false type error no matter what if `isPaginated` is not explicitly set to true
            isPaginated={!showOnlyDefault as true}
            hasGlobalSearch
            hasFilterColumns
            styleConfig={{
              rows: (rowData) =>
                css([
                  {
                    padding: theme.spacing(1),
                    p: {
                      margin: 0,
                      lineHeight: 1,
                    },
                  },
                  rowData.phoneNumber === defaultPhoneNumber && {
                    backgroundColor: theme.colors.primary5,
                  },
                ]),
            }}
            clientPaginationConfig={{
              showNumRowSelector: !showOnlyDefault,
            }}
            hasResizeColumns
            wrapperStyle={css({
              display: 'block',
              // Calculate the maximum width of the page to avoid overflow horizontally
              maxWidth: `calc(100vw - 320px - ${theme.spacing(10)} - ${theme.spacing(
                4
              )})`,
            })}
          />
          {!!defaultPhoneData && (
            <span css={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
              <TextButton onClick={() => setShowOnlyDefault((prev) => !prev)}>
                {showOnlyDefault
                  ? 'Show non-default numbers'
                  : 'Hide non-default numbers'}
              </TextButton>
            </span>
          )}
        </>
      )}
      {hasRequiredAcls && (
        <div
          css={{
            display: 'flex',
            columnGap: theme.spacing(3),
          }}
        >
          <Accordion
            css={{
              width: 525,
            }}
          >
            <Accordion.Item value="unsafe">
              <Accordion.Header title="10DLC Special Tools - UNSAFE" />
              <Accordion.Body
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>
                  Do NOT use unless you know what you're doing. Nothing these buttons do
                  is tracked by the normal 10DLC systems in Weave. Using these can break
                  10DLC registration for a number and block texting for the customer!
                </Text>
                <div
                  css={{
                    marginBottom: theme.spacing(2),
                    maxWidth: 400,
                  }}
                >
                  <DropdownField
                    label="Phone Number"
                    {...phoneNumberUnsafeDropdownField}
                    name="phoneNumber"
                    css={{
                      width: 300,
                    }}
                  >
                    {allStatusDetails.map((details, i) => (
                      <DropdownField.Option value={details.phoneNumber} key={i}>
                        {phone(details.phoneNumber, {
                          format: 'standard',
                        })}
                      </DropdownField.Option>
                    ))}
                  </DropdownField>
                </div>
                {showLoader ? (
                  <SpinningLoader />
                ) : (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: theme.spacing(2),
                    }}
                  >
                    <PrimaryButton
                      css={{
                        maxWidth: 400,
                      }}
                      disabled={
                        specialActionsDisabled || !phoneNumberUnsafeDropdownField?.value
                      }
                      onClick={() => {
                        fetchCampaignForPhoneData();
                      }}
                    >
                      Check 10DLC Phone Registration Status
                    </PrimaryButton>
                    <PrimaryButton
                      css={{
                        maxWidth: 400,
                      }}
                      disabled={
                        specialActionsDisabled || !phoneNumberUnsafeDropdownField?.value
                      }
                      onClick={() => {
                        unassignPhone({
                          phoneNumber: phoneNumberUnsafeDropdownField?.value,
                          cnp: getCnpByPhoneNumber(phoneNumberUnsafeDropdownField?.value),
                        });
                      }}
                    >
                      Unregister Phone Number
                    </PrimaryButton>
                    <PrimaryButton
                      css={{
                        maxWidth: 400,
                      }}
                      disabled={
                        specialActionsDisabled || !phoneNumberUnsafeDropdownField?.value
                      }
                      onClick={() => {
                        assignPhone({
                          phoneNumber: phoneNumberUnsafeDropdownField?.value,
                          cnp: getCnpByPhoneNumber(phoneNumberUnsafeDropdownField?.value),
                          campaignId: getCampaignIdByPhoneNumber(
                            phoneNumberUnsafeDropdownField?.value
                          ),
                        });
                      }}
                    >
                      Register Phone Number
                    </PrimaryButton>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion
            css={{
              width: 525,
            }}
          >
            <Accordion.Item value="rare">
              <Accordion.Header title="10DLC Special Tools - Rare Actions" />
              <Accordion.Body
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>Do NOT use unless you know what you're doing.</Text>
                <div
                  css={{
                    marginBottom: theme.spacing(2),
                    maxWidth: 400,
                  }}
                >
                  <DropdownField
                    label="Phone Number"
                    {...phoneNumberRareDropdownField}
                    name="phoneNumber"
                    css={{
                      width: 300,
                    }}
                  >
                    {allStatusDetails
                      .filter(
                        (phone) =>
                          phone.status !==
                          PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_IN_PROGRESS
                      )
                      .map((details, i) => (
                        <DropdownField.Option value={details.phoneNumber} key={i}>
                          {phone(details.phoneNumber, {
                            format: 'standard',
                          })}
                        </DropdownField.Option>
                      ))}
                  </DropdownField>
                </div>
                {showLoader ? (
                  <SpinningLoader />
                ) : (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      rowGap: theme.spacing(2),
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: theme.spacing(2),
                        width: 300,
                      }}
                    >
                      <PrimaryButton
                        disabled={
                          specialActionsDisabled || !phoneNumberRareDropdownField?.value
                        }
                        onClick={() => {
                          handleDetachPhone(phoneNumberRareDropdownField?.value);
                        }}
                      >
                        Detach Phone From Campaign
                      </PrimaryButton>
                      <IconButton onClick={openDetachPhoneInfoModal} label="">
                        <InfoIcon color="primary" />
                      </IconButton>
                    </div>
                    {(locationData?.Type as unknown as string) === 'Demo' ||
                    (locationData?.Type as unknown as string) === 'Dev' ||
                    (locationData?.Type as unknown as string) === 'Test' ? (
                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: theme.spacing(2),
                          width: 300,
                        }}
                      >
                        <PrimaryButton
                          disabled={specialActionsDisabled}
                          onClick={openConnectToWeaveBrandConfirmationModal}
                        >
                          Assign This Location to Weave Brand
                        </PrimaryButton>
                        <IconButton onClick={openConnectToWeaveBrandModal} label="">
                          <InfoIcon color="primary" />
                        </IconButton>
                      </div>
                    ) : null}
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      <Modal {...campaignDetailsModalProps}>
        <Modal.Header onClose={campaignDetailsModalProps.onClose}>
          Registration Status
        </Modal.Header>
        <Modal.Body css={{ paddingTop: theme.spacing(2) }}>
          <Text weight="bold">
            Campaign Id:{' '}
            <Text as="span" weight="regular">
              {campaignDetails?.campaignId}
            </Text>
          </Text>
          <Text weight="bold">
            Fully Provisioned:{' '}
            <Text as="span" weight="regular">
              {campaignDetails?.fullyProvisioned ? 'Yes' : 'No'}
            </Text>
          </Text>
          {!!campaignDetails?.provisioningFailureReason ? (
            <Text weight="bold">
              Failure Reason:{' '}
              <Text as="span" weight="regular">
                {campaignDetails?.provisioningFailureReason}
              </Text>
            </Text>
          ) : null}
          {getCnpByPhoneNumber(phoneNumberUnsafeDropdownField?.value) !==
          TCRCNP.TCR_CNP_TELNYX ? null : (
            <Text weight="bold">
              Provisioning Status:{' '}
              <Text as="span" weight="regular">
                {
                  assignmentStatusMap[
                    campaignDetails?.telnyxProvisioningStatus ??
                      AssignmentStatus_Enum.UNSPECIFIED
                  ]
                }
              </Text>
            </Text>
          )}
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Done"
          onPrimaryClick={campaignDetailsModalProps.onClose}
        />
      </Modal>
      <ConfirmationModal
        title="Are you sure?"
        message="Registering any number other than the default SMS number may result in an unneccessary charge to Weave."
        show={showConfirmationModal && !!selectedPhoneNumber}
        onClose={() => handleCloseModal()}
        onConfirm={async () => {
          if (selectedPhoneNumber) {
            await handleRegistration(selectedPhoneNumber);
            handleCloseModal();
          } else {
            alert.error({ message: 'Something went wrong. Please try again' });
          }
        }}
      />
      <Modal {...detachPhoneInfoModalProps}>
        <Modal.Header onClose={detachPhoneInfoModalProps.onClose}>
          Detach Phone Information
        </Modal.Header>
        <Modal.Body css={{ paddingTop: theme.spacing(2) }}>
          <Text>
            Detach Phone From Campaign will drop Weave's association between a phone
            number and a 10DLC campaign.
          </Text>
          <Text>
            If the phone number is Registered, the record in Weave documenting that will
            be deleted. However, the phone number will still actually be Registered until
            another action is taken, e.g., registering the phone to a new campaign.
          </Text>
          <Text>
            If the phone number has a Pending/Failed/Rejected status record, a new phone
            registration status record will be created with status Canceled. This will
            break the phone's association with the campaign it was going to be registered
            to, allowing it to be registered to a new campaign.
          </Text>
          <Text weight="bold">
            Example 1:{' '}
            <Text as="span" weight="regular">
              Phone is Pending registration to Telnyx campaign, but the phone was migrated
              to Bandwidth. After detachment, phone will be able to be registered to a
              Bandwidth campaign, and it's new status will be either Registerable Slowly
              or Registerable Quickly depending on if a Bandwidth campaign exists.
            </Text>
          </Text>
          <Text weight="bold">
            Example 2:{' '}
            <Text as="span" weight="regular">
              Phone is Registered to an approved Bandwidth campaign, but somehow a
              campaign for a different location. After detachment, the phone will still be
              Registered and able to text, but its status will be either Registerable
              Slowly or Registerable Quickly depending on if a Bandwidth campaign exists
              for the phone's location. When the phone is In Progress registering to a new
              campaign, it may temporarily be unable to send messages.
            </Text>
          </Text>
          <Text weight="bold">
            Example 3:{' '}
            <Text as="span" weight="regular">
              Phone is Rejected for a rejected campaign. After detachment, the phone will
              still have a Rejected status, but Weave won't automatically try to register
              it to the rejected campaign once it's approved.
            </Text>
          </Text>
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Done"
          onPrimaryClick={detachPhoneInfoModalProps.onClose}
        />
      </Modal>
      <Modal {...connectToWeaveBrandModalProps}>
        <Modal.Header onClose={connectToWeaveBrandModalProps.onClose}>
          Assign to Weave Brand Information
        </Modal.Header>
        <Modal.Body css={{ paddingTop: theme.spacing(2) }}>
          <BrandInfoText />
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Done"
          onPrimaryClick={connectToWeaveBrandModalProps.onClose}
        />
      </Modal>
      <Modal {...connectToWeaveBrandConfirmationModalProps}>
        <Modal.Header onClose={connectToWeaveBrandConfirmationModalProps.onClose}>
          Are you sure you want to proceed?
        </Modal.Header>
        <Modal.Body css={{ paddingTop: theme.spacing(2) }}>
          <BrandInfoText />
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Confirm"
          onPrimaryClick={() => {
            connectLocation({
              brandId: WEAVE_BRAND_ID,
              locationId,
            });
          }}
          secondaryLabel="Cancel"
          onSecondaryClick={connectToWeaveBrandConfirmationModalProps.onClose}
        />
      </Modal>
    </Page>
  );
};

const referenceIdStyles: Record<string, InterpolationWithTheme<any>> = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    p: { margin: 0 },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    width: 315,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as const;

const registrationStatusIndicatorsStyles: Record<string, InterpolationWithTheme<any>> = {
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
  },
} as const;

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => (
  <Text size="medium" css={{ whiteSpace: 'normal', margin: 0 }}>
    {title}
  </Text>
);
